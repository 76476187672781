import { useHourlyTimeSeriesOptions, useSystemResults } from '@/stores/systemAnalysisStore';
import TimeSeriesResult from './TimeSeriesResult';
import { BAR_CHART, LINE_CHART } from '@/consts';

const TimeSeriesResultContainer = () => {
  const options = useHourlyTimeSeriesOptions();
  const results = useSystemResults();

  if (!options.length) {
    return null;
  }

  const isHydrogenProduction = options[0]?.systemType === 'hydrogen_production';
  const chartType = isHydrogenProduction ? BAR_CHART : LINE_CHART;
  const initPlot = options[0]?.value;
  const initUnit = options[0]?.unit;

  return (
    <TimeSeriesResult
      initPlot={initPlot}
      initUnit={initUnit}
      chartType={chartType}
      results={results}
      graphOptions={options}
    />
  );
};

export default TimeSeriesResultContainer;
