import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { arrayToObject } from '@/utils/miscUtils';
import ErrorMessage from './ErrorMessage';
import ParameterFields from '../ParameterFields';
import { PARAM_TYPES_WITH_DEPENDENCIES } from '@/consts';
import DerivedFields from './DerivedFields';

const BlockField = ({ param }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const { name, params, validators: validate } = param;
  const dependencyParams = params.filter(({ type }) => PARAM_TYPES_WITH_DEPENDENCIES.includes(type));
  const errorMessage = errors?.[name]?.root?.message;
  const { fields, append, remove } = useFieldArray({
    control,
    rules: { validate },
    name,
  });

  const addBlock = e => {
    const block = arrayToObject(params, 'name', 'defaultValue');
    append(block);
    setSelectedIndex(fields.length);
    e.preventDefault();
    e.stopPropagation();
  };

  const removeBlock = index => {
    remove(index);
    if (fields.length >= 1) {
      setSelectedIndex(0);
    }
  };

  return (
    <div className="flex mt-6 gap-6 min-h-0">
      <div className="w-1/2 overflow-scroll">
        <div className="mb-4 flex items-center gap-2">
          <select
            className="select bg-gray-150 border-0 w-full select-sm focus:outline-none"
            value={selectedIndex}
            onChange={e => setSelectedIndex(Number(e.target.value))}
          >
            {fields.map((field, index) => (
              <option key={field.id} value={index}>
                {field.label || `${param.name} ${index + 1}`}
              </option>
            ))}
          </select>
          <button onClick={addBlock} className="btn btn-neutral bg-gray-150 border-0 btn-sm mr-2 rounded-md">
            <PlusIcon className="h-5 w-5" />
          </button>
        </div>

        {fields.length > 0 && (
          <div className="pl-2 pr-2 mb-4">
            <ParameterFields index={selectedIndex} params={params} parentName={name} field={fields[selectedIndex]} />
            <div className="flex justify-center mt-2">
              <button onClick={() => removeBlock(selectedIndex)} className="btn btn-sm btn-circle btn-ghost">
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        )}

        <div className="col-span-2 flex justify-center">
          <ErrorMessage text={errorMessage} />
        </div>
      </div>
      <div className="w-1/2">
        {dependencyParams?.length > 0 && (
          <DerivedFields params={dependencyParams} index={selectedIndex} parentName={name} />
        )}
      </div>
    </div>
  );
};

BlockField.propTypes = {
  param: PropTypes.object,
};

export default BlockField;
