import { useNavigation } from 'react-router-dom';
import PropTypes from 'prop-types';

const SubmitButton = ({ label }) => {
  const { state } = useNavigation();
  const isPending = state === 'loading' || state === 'submitting';

  return (
    <button type="submit" className={`btn btn-primary btn-sm ${isPending ? 'btn-disabled' : ''}`}>
      {isPending && <span className="loading loading-spinner"></span>}
      {isPending ? 'Please wait...' : label}
    </button>
  );
};

export default SubmitButton;

SubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
};
