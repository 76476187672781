import { Link, Form, useLoaderData } from 'react-router-dom';
import PropTypes from 'prop-types';
import SubmitButton from '../core/form/SubmitButton';

const EditProjectModal = ({ title }) => {
  const pathway = useLoaderData();
  const name = pathway?.name ?? '';

  return (
    <dialog className="modal" open>
      <div className="modal-box">
        <Form method="post">
          <h3 className="font-bold text-lg">{title}</h3>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Name</span>
            </div>
            <input
              type="text"
              name="name"
              placeholder="Enter project name"
              className="input input-bordered input-sm w-full"
              defaultValue={name}
            />
          </label>
          <div className="modal-action">
            <Link className="btn btn-sm btn-secondary" to="/" replace>
              Cancel
            </Link>
            <SubmitButton label="Save" />
          </div>
        </Form>
      </div>
    </dialog>
  );
};

EditProjectModal.propTypes = {
  title: PropTypes.string,
};

export default EditProjectModal;
