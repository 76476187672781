import FormRow from '@/components/core/form/FormRow';
import PropTypes from 'prop-types';
import Select from '@/components/core/form/Select';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { flowRateMap, flowRateOptions, HOURS_PER_YEAR } from '@/consts';
import { useEffect } from 'react';

const TimeSeriesForm = ({ defaultValues, pageOptions, graphOptions, unitOptions = [], onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { control, setValue, reset, getValues } = methods;
  const [flowRate, plot, unit] = useWatch({
    control,
    name: ['flowRate', 'plot', 'unit'],
    defaultValue: defaultValues,
  });

  const unitFromOptions = unitOptions.find(u => u.value === unit);
  const firstUnit = unitFromOptions || unitOptions?.[0];

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (firstUnit?.value) {
      setValue('unit', firstUnit?.value);
    }
  }, [plot, setValue, firstUnit?.value]);

  useEffect(() => {
    setValue('pageIndex', 0);
  }, [flowRate, setValue]);

  const handleChange = e => {
    const values = getValues();
    const value = !isNaN(e.target.value) && e.target.value !== '' ? Number(e.target.value) : e.target.value;

    values[e.target.name] = value;
    onSubmit(values);
  };

  return (
    <FormProvider {...methods}>
      <div className="group node">
        <div className="mt-2 grid grid-cols-auto-fit-0 gap-1">
          <FormRow className="overflow-hidden" label="Display flows per" name="flowRate">
            <Select
              className="w-full truncate"
              name="flowRate"
              options={flowRateOptions}
              onChange={handleChange}
              registerOptions={{ valueAsNumber: true }}
            />
          </FormRow>
          {flowRate !== HOURS_PER_YEAR && (
            <FormRow className="overflow-hidden" label={flowRateMap[flowRate]} name="pageIndex">
              <Select
                className="w-full truncate"
                name="pageIndex"
                options={pageOptions}
                onChange={handleChange}
                registerOptions={{ valueAsNumber: true }}
              />
            </FormRow>
          )}
          {unitOptions.length !== 0 && (
            <FormRow className="overflow-hidden" label="Unit" name="unit">
              <Select className="w-full truncate" name="unit" options={unitOptions} onChange={handleChange} />
            </FormRow>
          )}
          {graphOptions?.length > 1 && (
            <FormRow className="overflow-hidden" label="Change Graph" name="plot">
              <Select className="w-full truncate" name="plot" options={graphOptions} onChange={handleChange} />
            </FormRow>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

TimeSeriesForm.propTypes = {
  defaultValues: PropTypes.object,
  pageOptions: PropTypes.arrayOf(PropTypes.object),
  graphOptions: PropTypes.arrayOf(PropTypes.object),
  unitOptions: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
};

export default TimeSeriesForm;
