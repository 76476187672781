import PropTypes from 'prop-types';

import { useState } from 'react';
import AnalysisBox from '../core/AnalysisBox';
import { PARAM_TYPES_WITH_DEPENDENCIES } from '@/consts';
import TimeSeriesFields from './fields/TimeSeriesFields';

const ParameterTabsView = ({ categories, conditionalChecks, renderParamsBySubCategory }) => {
  const [activeTab, setActiveTab] = useState(Object.keys(categories)[0] || '');

  const renderParamsByCategory = (categoryName, isActive) => {
    const paramsByCategory = categories[categoryName]?.params;

    if (!paramsByCategory) {
      return null;
    }

    const regularParams = paramsByCategory.filter(({ type }) => !PARAM_TYPES_WITH_DEPENDENCIES.includes(type));
    const dependencyParams = paramsByCategory.filter(({ type }) => PARAM_TYPES_WITH_DEPENDENCIES.includes(type));

    if (dependencyParams.length > 0) {
      return (
        <div className="flex mt-6 gap-6 min-h-0">
          <div className="w-1/2 overflow-auto">{renderParamsBySubCategory(regularParams)}</div>
          <div className="w-1/2">{isActive && <TimeSeriesFields params={dependencyParams} />}</div>
        </div>
      );
    }

    const hasNestedParams = paramsByCategory.some(({ params }) => !!params);

    if (hasNestedParams) {
      return renderParamsBySubCategory(paramsByCategory);
    }

    const className = paramsByCategory.length > 1 ? `grid-cols-3` : `grid-cols-2`;

    return (
      <div className={`grid ${className} mt-6 gap-6 overflow-auto`}>{renderParamsBySubCategory(paramsByCategory)}</div>
    );
  };

  return (
    <AnalysisBox>
      <ul className="flex gap-x-2 border-b border-gray-100">
        {Object.values(categories).map(category => {
          const { categoryName, params } = category;
          const showCategory = params.some(({ name: paramName }) => conditionalChecks[paramName].fieldCondMet);
          return (
            showCategory && (
              <li key={categoryName} className="inline-block relative">
                <button
                  type="button"
                  onClick={() => {
                    setActiveTab(categoryName);
                  }}
                  className={`text-sm flex items-center pl-3 pr-3 px-1 pt-2 pb-3 rounded hover:bg-gray-100 ${activeTab === categoryName ? 'after:content-[""] after:h-[3px] after:bg-blue-400 after:w-full text-gray-800 after:absolute after:bottom-0 after:left-0 after:rounded-t-sm' : 'text-gray-800'}`}
                >
                  {categoryName || 'Generators'}
                </button>
              </li>
            )
          );
        })}
      </ul>
      <div className="flex min-h-0 flex-col">
        {Object.values(categories).map(({ categoryName }) => (
          <div
            className={`${activeTab === categoryName ? 'visible flex flex-col min-h-0' : 'hidden'}`}
            key={categoryName}
          >
            {renderParamsByCategory(categoryName, activeTab === categoryName)}
          </div>
        ))}
      </div>
    </AnalysisBox>
  );
};

ParameterTabsView.propTypes = {
  categories: PropTypes.object,
  renderParamsBySubCategory: PropTypes.func,
  conditionalChecks: PropTypes.object,
};

export default ParameterTabsView;
