import filterNodeTypes from '@/utils/filterNodeTypes';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { resourcesActions } from './resourcesStore';
import { NODE_TYPES, SYSTEM_NAMES_TO_TYPES, SYSTEM_STATUS } from '@/consts';

const initialValues = {
  searchParams: {},
  activeCategory: null,
  nodeTypesByCategory: {},
  productList: [],
};

const productList = [
  'Select Product',
  'Ammonia',
  'CO₂',
  'Coal',
  'Electricity',
  'Hydrogen',
  'Liquid Hydrogen',
  'Liquid Natural Gas',
  'Natural Gas',
  'Nitrogen',
];

const useRegistryStore = create()(immer(() => initialValues));

const { setState: set, getState: get } = useRegistryStore;

// actions
export const registryActions = {
  init: registry => {
    const activeCategory = registry?.[0]?.categories?.[0];
    const allNodes = [];
    const systemNodeTypes = [];
    const nodeTypesByCategory = registry.reduce((acc, nodeType) => {
      const { categories, search_terms, label } = nodeType;
      const searchIndex = [label, ...search_terms].join(' ');

      nodeType.searchIndex = searchIndex;

      categories?.forEach(category => {
        if (category) {
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(nodeType);
        }
      });

      if (categories?.includes('Systems')) {
        systemNodeTypes.push(nodeType);
      } else {
        allNodes.push(nodeType);
      }
      return acc;
    }, {});

    nodeTypesByCategory.All = allNodes;

    set({ nodeTypesByCategory, systemNodeTypes, productList, activeCategory });
  },

  updateSearch: searchParams => set({ searchParams }),
  setActiveCategory: activeCategory => set({ activeCategory }),

  loadSystems: () => {
    const { systemNodeTypes = [], nodeTypesByCategory } = get();
    const systems = resourcesActions.getSystems()?.filter(system => system.status === SYSTEM_STATUS.succeeded);
    const systemNodes = [];

    systemNodeTypes.forEach(nodeType => {
      const systemType = SYSTEM_NAMES_TO_TYPES[nodeType.name];
      systems.forEach(system => {
        if (system.system_type === systemType) {
          systemNodes.push({
            ...nodeType,
            label: system.name,
            type: NODE_TYPES.system,
            data: {
              system,
            },
          });
        }
      });
    });

    set({ nodeTypesByCategory: { ...nodeTypesByCategory, Systems: systemNodes } });
  },
  clear: () => set({ searchParams: {} }),
};

export const useProductList = () => useRegistryStore(store => store.productList);
export const useCategories = () => useRegistryStore(store => Object.keys(store.nodeTypesByCategory));
export const useActiveNodeTypes = () =>
  useRegistryStore(store => {
    const { searchParams, nodeTypesByCategory, activeCategory } = store;
    const { term, product } = searchParams;
    let activeNodeTypes = filterNodeTypes(term, activeCategory, product, nodeTypesByCategory);

    return activeNodeTypes;
  });
export const useActiveCategory = () => useRegistryStore(store => store.activeCategory);

export default useRegistryStore;
