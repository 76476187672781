import PropTypes from 'prop-types';

export const ChangelogItem = ({ number, title, description }) => {
  return (
    <li className="flex items-start gap-2 text-sm mt-4">
      <div className="flex-shrink-0 mb-2 rounded-full w-7 h-7 flex items-center justify-center bg-green-400 text-white font-bold">
        {number}
      </div>
      <div>
        <b>{title}</b> - {description}
      </div>
    </li>
  );
};

ChangelogItem.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
