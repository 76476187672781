import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import PathwaysIcon from '@/assets/react-icons/md/PathwaysIcon.svg?react';
import PathwayActionsDropdown from './PathwayActionsDropdown';
import { usePathways } from '@/stores/resourcesStore';
import useIsAdmin from '@/hooks/useIsAdmin';
import ListHeader from '../core/ListHeader';
import ListItemLink from '../core/ListItemLink';
import { isPathwaySucceeded } from '@/utils/pathwayUtils';

const PathwayList = () => {
  const pathways = usePathways();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const addNew = event => {
    event.stopPropagation();
    navigate(`/projects/${projectId}/build/pathways/new`);
  };

  const isAdmin = useIsAdmin();

  return (
    <div className="divide-y divide-gray-75">
      <ListHeader onAdd={addNew} title="Pathways" icon={PathwaysIcon} />
      {pathways.length > 0 && (
        <ul className="p-2">
          {pathways.map(pathway => (
            <li key={pathway.id} className="group/item">
              <ListItemLink to={`/projects/${projectId}/build/pathways/${pathway.id}`}>
                <span className="truncate">{pathway.name}</span>
                {isPathwaySucceeded(pathway) && (
                  <div className="group-hover/item:hidden">
                    <CheckCircleIcon className="text-green-500 h-4 w-4" />
                  </div>
                )}
                <div className="group-hover/item:flex hidden">
                  <PathwayActionsDropdown pathway={pathway} isAdmin={isAdmin} />
                </div>
              </ListItemLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

PathwayList.propTypes = {
  project: PropTypes.object,
};

export default PathwayList;
