import PropTypes from 'prop-types';
import useCheckConditionals from '../useCheckConditionals';
import TimeSeriesFields from './TimeSeriesFields';

const DerivedFields = ({ params, index, parentName }) => {
  const prefix = parentName ? `${parentName}.${index}.` : '';
  const conditionalChecks = useCheckConditionals(params, prefix);
  const validParams = params.filter(param => conditionalChecks[`${prefix}${param.name}`].fieldCondMet);

  if (!validParams?.length) {
    return null;
  }

  // TODO: handle other types of Derived fields
  return <TimeSeriesFields key={prefix} params={validParams} index={index} parentName={parentName} />;
};

DerivedFields.propTypes = {
  params: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  index: PropTypes.number,
  parentName: PropTypes.string,
};

export default DerivedFields;
