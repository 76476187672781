import AnalysesRoute from '@/routes/analyses/AnalysesRoute';
import { getPathways } from '@/api/pathways';
import { analysisActions } from '@/stores/analysisStore';
import { defer } from 'react-router-dom';
import PathwayAnalysisRoute from './PathwayAnalysisRoute';
import SystemAnalysisRoute from './SystemAnalysisRoute';
import { systemAnalysisActions } from '@/stores/systemAnalysisStore';
import { getSystems } from '@/api/systems';
import { SYSTEM_STATUS } from '@/consts';

const analysesRouter = {
  path: '/projects/:projectId/analysis',
  element: <AnalysesRoute />,
  loader: async ({ params }) => {
    const promise = Promise.all([
      getPathways(params.projectId),
      getSystems(params.projectId, { status: SYSTEM_STATUS.succeeded }),
    ]);

    return defer({ promise });
  },
  handle: {
    title: _ => {
      return { title: `Analyze` };
    },
  },
  children: [
    {
      path: '/projects/:projectId/analysis/pathways/:pathwayId',
      id: 'analysis',
      element: <PathwayAnalysisRoute />,
      errorElement: <PathwayAnalysisRoute />,
      loader: async ({ params: { pathwayId } }) => {
        const promise = analysisActions.init(pathwayId);
        return defer({ promise });
      },
      handle: {
        crumb: pathway => ['Pathways', pathway?.name],
        title: pathway => {
          return { title: `Analyze Pathway: ${pathway?.name}` };
        },
      },
    },
    {
      path: '/projects/:projectId/analysis/systems/:systemId',
      id: 'system-analysis',
      element: <SystemAnalysisRoute />,
      errorElement: <SystemAnalysisRoute />,
      loader: async ({ params: { systemId } }) => {
        const promise = systemAnalysisActions.init(systemId);
        return defer({ promise });
      },
      handle: {
        crumb: system => ['Systems', system?.name],
        title: system => {
          return { title: `Analyze System: ${system?.name}` };
        },
      },
    },
  ],
};

export default analysesRouter;
