import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER, ANALYSIS_STATUS } from '@/consts';
import { isEmpty, runInterval } from '@/utils/miscUtils';
import { getAnalysisStatus } from '@/api/analyses';
import { subscribeWithSelector } from 'zustand/middleware';

const initialValues = {
  analysisId: null,
  isStale: false,
  analysisTypes: {},
};

const useAnalysisStatusStore = create()(subscribeWithSelector(immer(() => initialValues)));
const { setState: set, getState: get } = useAnalysisStatusStore;

export const subscriptions = [];
const intervals = [];

// actions
export const analysisStatusActions = {
  init: async caseData => {
    const { analysis_id: analysisId, analysis_status: initStatus, analysis_stale: isStale } = caseData;

    analysisStatusActions.clear();

    set({ analysisId, isStale });

    if (initStatus) {
      set(state => {
        Object.entries(initStatus).forEach(([type, status]) => {
          state.analysisTypes[type] = { status };
        });
      });
    }

    Object.entries(initStatus ?? {}).forEach(([type, status]) => {
      if (status !== ANALYSIS_STATUS.succeeded && status !== ANALYSIS_STATUS.failed) {
        analysisStatusActions.checkStatusPeriodically(type);
      }
    });
  },

  addSubscription: subscription => {
    subscriptions.push(subscription);
  },

  clearSubscriptions: () => {
    subscriptions.forEach(unsubscribe => unsubscribe());
    subscriptions.length = 0;
  },

  clearIntervals: () => {
    intervals.forEach(interval => interval());
    intervals.length = 0;
  },

  checkStatus: async analysisType => {
    const { analysisId } = get();

    if (!analysisId) {
      return false;
    }

    try {
      const { data } = await getAnalysisStatus(analysisId, analysisType);
      const { status } = data ?? {};

      set(state => {
        state.analysisTypes[analysisType] = data;
      });

      if (status === ANALYSIS_STATUS.succeeded || status === ANALYSIS_STATUS.failed) {
        return true;
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        return false;
      }

      set(state => {
        state.analysisTypes[analysisType] = {
          status: ANALYSIS_STATUS.failed,
        };
      });

      console.error('error', err);
      return true;
    }

    return false;
  },

  checkStatusPeriodically: analysisType => {
    const stopInterval = runInterval(
      () => analysisStatusActions.checkStatus(analysisType),
      CHECK_FIRST_DELAY,
      CHECK_SECOND_DELAY,
      CHANGE_DELAY_AFTER,
    );
    intervals.push(stopInterval);
  },

  markAsStale: () => set({ isStale: true }),

  isInProgress: () => {
    const { analysisTypes } = get();

    if (isEmpty(analysisTypes)) {
      return false;
    }

    return !!Object.values(analysisTypes).find(
      type => type.status !== ANALYSIS_STATUS.succeeded && type.status !== ANALYSIS_STATUS.failed,
    );
  },

  clear: () => {
    set({ analysisTypes: {} });
    analysisStatusActions.clearSubscriptions();
    analysisStatusActions.clearIntervals();
  },
};

// selectors
export const useIsAnalysisStale = () => useAnalysisStatusStore(store => store.isStale);

export const useIsAnalysisRunning = () =>
  useAnalysisStatusStore(store => {
    const { analysisTypes } = store;

    return !!Object.values(analysisTypes).find(type => type.status === ANALYSIS_STATUS.running);
  });

export const useIsAnalysisPending = () =>
  useAnalysisStatusStore(store => {
    const { analysisTypes } = store;

    return !!Object.values(analysisTypes).find(type => type.status === ANALYSIS_STATUS.pending);
  });

export const useIsLCAAndTEASucceeded = () =>
  useAnalysisStatusStore(store => {
    const { analysisTypes } = store;

    return (
      analysisTypes?.lca?.status === ANALYSIS_STATUS.succeeded &&
      analysisTypes?.tea?.status === ANALYSIS_STATUS.succeeded
    );
  });

export const useIsLCAAndTEAStatus = () => {
  return useAnalysisStatusStore(store => {
    const { analysisTypes } = store;

    if (
      analysisTypes?.lca?.status === ANALYSIS_STATUS.succeeded &&
      analysisTypes?.tea?.status === ANALYSIS_STATUS.succeeded
    ) {
      return ANALYSIS_STATUS.succeeded;
    }

    if (analysisTypes?.lca?.status === ANALYSIS_STATUS.error || analysisTypes?.tea?.status === ANALYSIS_STATUS.error) {
      return ANALYSIS_STATUS.error;
    }

    return ANALYSIS_STATUS.running;
  });
};

export const useIsLCAAndTEAInProgress = () =>
  useAnalysisStatusStore(store => {
    const { analysisTypes } = store;

    const types = ['lca', 'tea'];

    return !!types.some(
      type =>
        analysisTypes[type].status !== ANALYSIS_STATUS.succeeded &&
        analysisTypes[type].status !== ANALYSIS_STATUS.failed,
    );
  });

export const useIsAnalysisInProgress = () =>
  useAnalysisStatusStore(store => {
    const { analysisTypes } = store;

    if (isEmpty(analysisTypes)) {
      return false;
    }

    return !!Object.values(analysisTypes).some(
      type => type.status !== ANALYSIS_STATUS.succeeded && type.status !== ANALYSIS_STATUS.failed,
    );
  });

export const useIsAnalysisFailed = () =>
  useAnalysisStatusStore(store => {
    const { analysisTypes } = store;
    return !!Object.values(analysisTypes).find(type => type.status === ANALYSIS_STATUS.failed);
  });

export const useAnalysisStatus = analysisType =>
  useAnalysisStatusStore(store => store.analysisTypes?.[analysisType]?.status);
export const useAnalysisProgress = analysisType =>
  useAnalysisStatusStore(store => store.analysisTypes?.[analysisType]?.progress);
export const useAnalysisTypes = () => useAnalysisStatusStore(store => store.analysisTypes);

export default useAnalysisStatusStore;
