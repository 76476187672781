import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import PathwayActionsDropdown from '@/components/pathways/PathwayActionsDropdown';
import { isPathwaySucceeded } from '@/utils/pathwayUtils';

const PathwayRow = ({ pathway, projectId, isAdmin }) => {
  const navigate = useNavigate();

  const viewAnalysis = e => {
    e.preventDefault();
    navigate(`/projects/${projectId}/analysis/pathways/${pathway.id}`);
  };

  const continueBuild = e => {
    e.preventDefault();
    navigate(`/projects/${projectId}/build/pathways/${pathway.id}`);
  };

  return (
    <tr className="bg-white">
      <td>
        <Link
          className="group flex justify-start rounded-md py-2 px-4 text-sm items-center leading-6 text-gray-700 hover:bg-gray-50"
          to={`/projects/${projectId}/build/pathways/${pathway.id}`}
        >
          <span className="truncate w-2/5">{pathway.name}</span>
          <span className="w-2/5 flex group-hover:hidden">
            {isPathwaySucceeded(pathway) ? (
              <div className="inline-flex rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Analysis Complete
              </div>
            ) : (
              <div className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                In Progress
              </div>
            )}
          </span>
          <span className="w-2/5 group-hover:flex hidden">
            {isPathwaySucceeded(pathway) ? (
              <button onClick={viewAnalysis} className="btn btn-xs btn-secondary">
                View Analysis
              </button>
            ) : (
              <button onClick={continueBuild} className="btn btn-xs btn-secondary">
                Continue Build
              </button>
            )}
          </span>
          <div className="w-1/5 justify-end items-center group-hover:flex hidden">
            <PathwayActionsDropdown pathway={pathway} isAdmin={isAdmin} />
          </div>
        </Link>
      </td>
    </tr>
  );
};

PathwayRow.propTypes = {
  pathway: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    analysis_status: PropTypes.object,
    analysis_stale: PropTypes.bool,
  }),
  projectId: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default PathwayRow;
