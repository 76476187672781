import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { isDefined, keyBy, zipObject } from '@/utils/miscUtils';
import { useSystemId } from '@/stores/systemStore';
import { getDerivedValue } from '@/api/systems';
import TimeSeriesResult from '@/components/systems/results/v2/TimeSeriesResult';
import { LINE_CHART } from '@/consts';
import { resolveParamName, resolveParamNames } from '@/utils/paramsUtils';
import get from 'just-safe-get';

let timeoutId;

const TimeSeriesFields = ({ params, index, parentName }) => {
  const paramsByName = keyBy(params, 'name');
  const [paramName, setParamName] = useState(params[0].name);
  const [isLoading, setIsLoading] = useState(true);
  const param = paramsByName[paramName];
  const dependencies = resolveParamNames(param.dependencies, index, parentName);
  const graphOptions = params.map(({ name, label }) => ({ value: name, label }));
  const { getValues, watch } = useFormContext();
  const values = watch(dependencies);

  const name = resolveParamName(paramName, index, parentName);
  const systemId = useSystemId();
  const [results, setResults] = useState(null);

  useEffect(
    () => {
      const fetchDerivedValue = async () => {
        try {
          const conversions = getValues('conversion');
          const dependencyValues = zipObject(dependencies, values);

          Object.entries(dependencyValues).forEach(([key, scalar]) => {
            const conversion = get(conversions, key);
            if (conversion && isDefined(scalar)) {
              const { unit } = JSON.parse(conversion);
              dependencyValues[key] = { scalar, unit };
            }
          });

          const { data } = await getDerivedValue(systemId, name, dependencyValues);
          setResults(data);
        } catch (error) {
          setResults(null);
        } finally {
          setIsLoading(false);
        }
      };

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(fetchDerivedValue, 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, JSON.stringify(values), systemId, getValues],
  );

  return (
    <div className="w-full">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <span className="loading loading-spinner" />
        </div>
      ) : (
        <TimeSeriesResult
          key={name}
          onChange={name => setParamName(name)}
          useContainer={false}
          graphOptions={graphOptions}
          initPlot={paramName}
          initUnit={results?.unit}
          chartType={LINE_CHART}
          results={{ [paramName]: results }}
        />
      )}
    </div>
  );
};

TimeSeriesFields.propTypes = {
  params: PropTypes.array.isRequired,
  index: PropTypes.number,
  parentName: PropTypes.string,
};

export default TimeSeriesFields;
