import PropTypes from 'prop-types';
import { useSystemResult } from '@/stores/systemAnalysisStore';
import { numberFormat, unitToString } from '@/utils/unitUtils';
import { parseCategories } from '@/utils/paramsUtils';
import { Fragment } from 'react';
import { isObject } from '@/utils/miscUtils';

const AttributesTable = ({ name, className = '' }) => {
  const result = useSystemResult(name);

  if (!result) {
    return null;
  }

  const { categories } = result;

  const renderParams = params => {
    return params.map(param => {
      return (
        <tr key={param.label} className="border-dashed !border-black/30 border-t border-b-0">
          <td className={className}>{param.label}</td>
          <td>{isObject(param.value) ? numberFormat(param.value?.scalar) : param.value}</td>
          <td className="text-right">{unitToString(param.value.unit)}</td>
        </tr>
      );
    });
  };

  const renderParamsBySubCategory = params => {
    const subCategories = parseCategories(params, 1);

    return Object.values(subCategories).map(({ categoryName, params }, index) => {
      return (
        <Fragment key={`subcategory-${index}`}>
          {categoryName ? (
            <>
              <tr className="font-bold bg-gray-50">
                <td colSpan={3}>{categoryName}</td>
              </tr>
              {renderParams(params)}
            </>
          ) : (
            renderParams(params)
          )}
        </Fragment>
      );
    });
  };

  const renderParamsByCategory = categoryName => {
    const paramsByCategory = categories[categoryName]?.params;

    if (!paramsByCategory) {
      return null;
    }
    return (
      <div
        className="prose min-w-full prose-sm prose-thead:text-sm prose-thead:font-semibold prose-thead:border-b prose-thead:border-gray-600 prose-td:px-2 prose-tr:border-transparent"
        key={categoryName}
      >
        <table>
          <thead>
            <tr>
              <td colSpan={3}>{categoryName}</td>
            </tr>
          </thead>
          <tbody>{renderParamsBySubCategory(paramsByCategory)}</tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      {Object.values(categories).map(({ categoryName }) => {
        return renderParamsByCategory(categoryName);
      })}
    </>
  );
};

AttributesTable.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

export default AttributesTable;
