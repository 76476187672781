import { Position } from '@xyflow/react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import InputOutputPorts from '@/components/nodes/InputOutputPorts';
import { memo } from 'react';
import { pathwayActions } from '@/stores/pathwayStore';
import { SYSTEM_TYPE_TO_LABEL } from '@/consts';

const SystemNode = ({ data: node }) => {
  const { id, inputs, outputs, system } = node;
  const { projectId } = useParams();
  const navigate = useNavigate();
  const removeNode = e => {
    e.stopPropagation();
    pathwayActions.removeNode(node.id);
  };

  const handleNodeClick = () => {
    navigate(`/projects/${projectId}/analysis/systems/${system.id}/`, { state: { node } });
  };

  return (
    <div
      className="group/item bg-base-100 border border-gray-300 w-112 rounded-lg shadow-lg hover:shadow-xl hover:border-gray-400"
      onClick={handleNodeClick}
    >
      <div className="flex">
        <div className="group/edit invisible group-hover/item:visible">
          <div onClick={removeNode} className="nodrag pt-2 pl-2">
            <XMarkIcon className="h-6 w-6 text-gray-400 hover:text-black" />
          </div>
        </div>
        <div className="flex-1">
          <p className="p-2 mt-4 text-xl font-semibold text-center">{system?.name}</p>
          <p className="mb-4 text-lg text-center">{SYSTEM_TYPE_TO_LABEL?.[system?.system_type]}</p>
        </div>
        <div className="flex m-2 justify-end group/edit invisible group-hover/item:visible">
          <ArrowTopRightOnSquareIcon className="w-6 h-6 text-gray-400 hover:text-black cursor-pointer" />
        </div>
      </div>
      <div>
        <div className="flex">
          <InputOutputPorts
            parentId={id}
            nodes={inputs}
            label="Inputs"
            handleType="input"
            handlePosition={Position.Left}
          />
          <InputOutputPorts
            parentId={id}
            nodes={outputs}
            label="Outputs"
            handleType="output"
            handlePosition={Position.Right}
          />
        </div>
      </div>
    </div>
  );
};

SystemNode.propTypes = {
  data: PropTypes.object,
};

const MemoizedSystemNode = memo(SystemNode);

export default MemoizedSystemNode;
