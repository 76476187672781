import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { Await, useAsyncValue, useMatches } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const BreadcrumbItem = ({ crumb, end }) => {
  const data = useAsyncValue();
  const crumbData = crumb.handle.crumb(data);
  const separatorClass = end === false ? '' : `after:content-['/'] after:ml-2`;

  if (Array.isArray(crumbData) && crumbData.length) {
    return (
      <>
        <span className="text-sm font-light after:ml-2 after:content-['/']">{crumbData[0]}</span>
        <NavLink
          to={crumb.pathname}
          role="tab"
          end={end}
          className={({ isActive }) => `${separatorClass} text-sm ${isActive ? 'font-medium' : 'font-light'}`}
        >
          <span className="flex items-center space-x-1">
            <span>{crumbData[1]}</span>
            {crumbData.length > 2 && <span>{crumbData[2]}</span>}
          </span>
        </NavLink>
      </>
    );
  }

  return (
    <NavLink
      to={crumb.pathname}
      role="tab"
      end={end}
      className={({ isActive }) => `text-sm ${separatorClass} ${isActive ? 'font-medium' : 'font-light'}`}
    >
      {crumb.handle.crumb(crumb.data)}
    </NavLink>
  );
};

BreadcrumbItem.propTypes = {
  crumb: PropTypes.object,
  end: PropTypes.bool,
};

const Breadcrumbs = () => {
  const matches = useMatches();
  const crumbs = matches.filter(match => Boolean(match.handle?.crumb));

  return (
    <>
      {crumbs.map((crumb, index) => {
        return (
          <Suspense fallback={null} key={`crumb-${index}`}>
            <Await resolve={crumb?.data?.promise ?? crumb.data} errorElement={<></>}>
              <BreadcrumbItem crumb={crumb} end={index !== crumbs.length - 1} />
            </Await>
          </Suspense>
        );
      })}
    </>
  );
};

export default Breadcrumbs;
