import { useMemo, useState } from 'react';
import Map, { Marker, Popup, FullscreenControl, NavigationControl } from 'react-map-gl';
import PropTypes from 'prop-types';
import config from '@/config';
import Pin from './Pin';
import mapboxGl from 'mapbox-gl';

const MapChart = ({ locations }) => {
  const [popupInfo, setPopupInfo] = useState(null);
  const pins = useMemo(
    () =>
      locations.map((location, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={location.lon}
          latitude={location.lat}
          anchor="bottom"
          onClick={e => {
            e.originalEvent.stopPropagation();
            setPopupInfo(location);
          }}
        >
          <Pin />
        </Marker>
      )),
    [locations],
  );

  const initialViewState = useMemo(() => {
    if (!locations?.length) {
      return { longitude: -100, latitude: 40, zoom: 2.5 };
    }

    const bounds = new mapboxGl.LngLatBounds();

    locations.forEach(location => {
      bounds.extend([Number(location.lon), Number(location.lat)]);
    });

    const [[west, south], [east, north]] = bounds.toArray();

    return {
      bounds: [
        [west - 0.5, south - 0.5],
        [east + 0.5, north + 0.5],
      ],
      maxZoom: 12.0,
    };
  }, [locations]);

  return (
    <div className="min-h-96">
      <Map
        mapLib={mapboxGl}
        mapboxAccessToken={config.mapBoxAccessToken}
        initialViewState={initialViewState}
        scrollZoom={false}
        style={{ position: 'relative', width: '100%', height: '400px' }}
        mapStyle="mapbox://styles/mapbox/light-v9"
      >
        {pins}
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />

        {popupInfo && (
          <Popup
            anchor="top"
            className="p-2"
            closeButton={false}
            longitude={Number(popupInfo.lon)}
            latitude={Number(popupInfo.lat)}
            onClose={() => setPopupInfo(null)}
          >
            <div className="p-2">
              <p className="text-md font-bold">{popupInfo.address}</p>
              <p className="text-md">
                {popupInfo.lat}, {popupInfo.lon}
              </p>
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
};

MapChart.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
};

export default MapChart;
