import PropTypes from 'prop-types';

const ProgressBar = ({ label, value = 0 }) => {
  const intValue = Math.round(Number(value));

  return (
    <div>
      {label && <p>{label}</p>}
      <progress className="progress progress-info w-full" value={intValue} max="100"></progress>
      <p className="text-xs">{intValue}% complete</p>
    </div>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
};
