import { ChangelogItem } from './ChangelogItem';
import changelog from '@/changelog';

const Changelog = () => {
  return (
    <div className="rounded-md bg-gray-100 p-8">
      <div className="font-bold text-lg mb-2">{changelog.title}</div>
      <ul>
        {changelog.items.map((item, index) => (
          <ChangelogItem
            key={`changelog-item-${index}`}
            number={index + 1}
            title={item.title}
            description={item.description}
          />
        ))}
      </ul>
    </div>
  );
};

export default Changelog;
