import { CheckCircleIcon, ArrowPathIcon, XCircleIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import ProgressBar from '../core/ProgressBar';
import { isEmpty } from '@/utils/miscUtils';
import { useEffect, useState } from 'react';
import usePrevious from '@/hooks/usePrevious';
import {
  useAnalysisTypes,
  useIsAnalysisInProgress,
  useIsAnalysisStale,
  useIsLCAAndTEAStatus,
} from '@/stores/analysisStatusStore';
import { ANALYSIS_STATUS, ANALYSIS_TYPES } from '@/consts';

const styles = {
  succeeded: 'bg-green-75',
  running: 'bg-blue-75',
  pending: 'bg-blue-75',
  failed: 'bg-red-75',
};

const iconStyles = {
  succeeded: 'stroke-green-400',
  running: 'stroke-blue-400',
  pending: 'stroke-blue-400',
  failed: 'stroke-red-400',
};

const icons = {
  succeeded: CheckCircleIcon,
  running: ArrowPathIcon,
  pending: ArrowPathIcon,
  failed: XCircleIcon,
};

const messages = {
  succeeded: label => `${label} Analysis Complete`,
  pending: label => `${label} Analysis in Pending`,
  running: label => `Processing ${label} Analysis`,
  failed: label => `${label} Analysis Unsuccessful`,
};

export const StatusAlert = ({ status, label, progress }) => {
  const [isVisible, setIsVisible] = useState(true);
  const previousStatus = usePrevious(status);

  useEffect(() => {
    if (status === ANALYSIS_STATUS.running) {
      setIsVisible(true);
      return;
    }

    if (status === ANALYSIS_STATUS.succeeded) {
      if (!previousStatus) {
        return setIsVisible(false);
      }

      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [status, previousStatus]);

  if (!isVisible) return null;

  const Icon = icons[status];
  const className = styles[status];
  const iconStyle = iconStyles[status];
  const message = messages?.[status]?.(label);

  return (
    <div className={`w-full rounded p-3 mt-5 ${className}`}>
      <div className="flex items-start gap-2">
        <Icon className={`h-5 w-5 flex-shrink-0 stroke-2 ${iconStyle}`} />
        <div>
          <p className="text-sm font-medium">{message}</p>
          {ANALYSIS_STATUS.failed === status && (
            <p className="text-xs">Our support team has been alerted and is looking into the issue</p>
          )}
          {progress >= 0 && status === ANALYSIS_STATUS.running && <ProgressBar value={progress} />}
        </div>
      </div>
    </div>
  );
};

StatusAlert.propTypes = {
  status: PropTypes.oneOf(['succeeded', 'running', 'pending', 'failed']).isRequired,
  label: PropTypes.string.isRequired,
  progress: PropTypes.number,
};

const StatusAlerts = () => {
  const types = useAnalysisTypes();
  const isAnalysisInProgress = useIsAnalysisInProgress();
  const isAnalysisStale = useIsAnalysisStale();
  const lcaAndTeaStatus = useIsLCAAndTEAStatus();

  if ((isAnalysisStale && !isAnalysisInProgress) || isEmpty(types)) {
    return null;
  }

  const otherAnalysisTypes = Object.entries(types).filter(([type]) => !['tea', 'lca'].includes(type));

  return (
    <div className="absolute flex flex-col top-14 right-0 mr-2 w-[290px]">
      <StatusAlert status={lcaAndTeaStatus} label="TEA + LCA" />
      {otherAnalysisTypes.map(
        ([type, { status, progress }]) =>
          status && (
            <StatusAlert key={type} status={status} label={ANALYSIS_TYPES[type]} progress={(progress ?? 0) * 100} />
          ),
      )}
    </div>
  );
};

export default StatusAlerts;
