import { useIsAnalysisStale, useIsLCAAndTEASucceeded } from '@/stores/analysisStatusStore';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { Link, useParams } from 'react-router-dom';

const OpenAnalysisButton = () => {
  const isLCAAndTEASucceeded = useIsLCAAndTEASucceeded();
  const isAnalysisStale = useIsAnalysisStale();
  const { projectId, pathwayId } = useParams();

  if (isLCAAndTEASucceeded && !isAnalysisStale) {
    return (
      <Link to={`/projects/${projectId}/analysis/pathways/${pathwayId}`} className="btn btn-sm btn-primary">
        <ChartBarIcon className="w-5 h-5" />
        View Analysis
      </Link>
    );
  }
};

export default OpenAnalysisButton;
