import { duplicateCase } from '@/api/cases';
import { createPathway, patchPathway } from '@/api/pathways';
import { isBuildPath } from '@/utils/miscUtils';
import PropTypes from 'prop-types';
import { Link, Form, useLoaderData, useActionData, useParams, redirect, json } from 'react-router-dom';
import ProjectSelect from '../projects/ProjectSelect';
import SubmitButton from '../core/form/SubmitButton';

const EditPathwayModal = ({ title }) => {
  const { pathway, projects } = useLoaderData() ?? {};
  const errors = useActionData();
  const name = pathway?.name ?? '';
  const caseId = pathway?.case_id ?? '';
  const { projectId } = useParams();
  const projectOptions = projects?.map(({ id, name }) => ({ label: name, value: id }));
  const defaultName = projects?.length ? `${name} [duplicate]` : name;

  return (
    <dialog className="modal" open>
      <div className="modal-box">
        <Form method="put">
          <h3 className="font-bold text-lg">{title}</h3>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Name</span>
            </div>
            <input
              type="text"
              name="name"
              placeholder="Enter pathway name"
              className="input input-bordered input-sm w-full"
              defaultValue={defaultName}
            />
            <input type="hidden" name="curName" defaultValue={name} />
            <input type="hidden" name="caseId" defaultValue={caseId} />
            <div className="label">
              <span className="label-text-alt text-error">{errors?.name}</span>
            </div>
          </label>
          {projectOptions?.length && <ProjectSelect defaultValue={projectId} options={projectOptions} />}
          <div className="modal-action">
            <Link className="btn btn-sm btn-secondary" to={-1} replace>
              Cancel
            </Link>
            <SubmitButton label="Save" />
          </div>
        </Form>
      </div>
    </dialog>
  );
};

EditPathwayModal.propTypes = {
  title: PropTypes.string,
};

EditPathwayModal.addAction = async ({ request, params }) => {
  const { projectId } = params;
  const form = await request.formData();
  const { name } = Object.fromEntries(form);
  const matchBuild = isBuildPath(request.url);

  const actionPath = matchBuild ? `/projects/${projectId}/build/pathways` : `/projects/${projectId}/dashboard`;

  try {
    const { data } = await createPathway(projectId, { name });
    return redirect(matchBuild ? `${actionPath}/${data.id}` : actionPath);
  } catch (error) {
    return redirect(`${actionPath}/new`);
  }
};

EditPathwayModal.editAction = async ({ request, params }) => {
  const { projectId, pathwayId } = params;
  const form = await request.formData();
  const { name } = Object.fromEntries(form);
  const matchBuild = isBuildPath(request.url);

  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;
  try {
    await patchPathway(pathwayId, { name });
    return redirect(matchBuild ? `${actionPath}/pathways/${pathwayId}` : actionPath);
  } catch (error) {
    return redirect(`${actionPath}/pathways/${pathwayId}/edit`);
  }
};

EditPathwayModal.duplicateAction = async ({ request, params }) => {
  const { projectId, pathwayId } = params;
  const form = await request.formData();
  const { name, curName, caseId, project_id } = Object.fromEntries(form);

  const matchBuild = isBuildPath(request.url);
  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;

  if (name === curName) {
    return json({ name: 'Please choose different name' });
  }

  try {
    const { data } = await duplicateCase(caseId, { name, project_id });
    return redirect(matchBuild ? `${actionPath}/pathways/${data.pathway_id}` : actionPath);
  } catch (err) {
    return redirect(
      matchBuild ? `${actionPath}/pathways/${pathwayId}/duplicate` : `${actionPath}/${pathwayId}/duplicate`,
    );
  }
};

export default EditPathwayModal;
