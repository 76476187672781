import { SYSTEM_STATUS } from '@/consts';

import {
  Squares2X2Icon,
  ChartBarIcon,
  CubeIcon,
  ArrowsRightLeftIcon,
  DocumentChartBarIcon,
  DocumentIcon,
  HomeIcon,
} from '@heroicons/react/24/outline';
import config from '@/config';
import BuildDropdownContent from '@/components/core/BuildDropdownContent';
import AnalyzeDropdownContent from '@/components/core/AnalyzeDropdownContent';
import ComparisonDropdownContent from '@/components/core/ComparisonDropdownContent';
import { getSucceededPathways } from './pathwayUtils';

const { featureFlags } = config;

const getPathwayUrl = (data, location, params) => {
  const {
    project: { id },
    pathways: { results: pathways },
    systems: { results: systems },
  } = data;

  const { pathwayId, systemId } = params;
  const { pathname } = location;
  const rootBuildPath = pathname.match(/build$/);
  const buildPath = pathname.match(/build/);

  if (buildPath && !rootBuildPath) {
    return pathname;
  }

  if (pathwayId && pathways?.find(pathway => pathway.id === pathwayId)) {
    return `/projects/${id}/build/pathways/${pathwayId}`;
  } else if (systemId && systems?.find(system => system.id === systemId)) {
    return `/projects/${id}/build/systems/${systemId}`;
  } else if (pathways?.length) {
    return `/projects/${id}/build/pathways/${pathways[0].id}`;
  } else if (systems?.length) {
    return `/projects/${id}/build/systems/${systems[0].id}`;
  }

  return `/projects/${id}/build`;
};

const getAnalyzeUrl = (data, location, params) => {
  const {
    project: { id },
    pathways: { results: pathways },
    systems: { results: systems },
  } = data;
  const { pathname } = location;
  const { pathwayId, systemId } = params;

  const analyses = getSucceededPathways(pathways?.results ?? []);
  const rootAnalysesPath = pathname.match(/analysis$/);
  const analysesPath = pathname.match(/analysis/);

  const succeededSystems = systems.filter(system => system.status === SYSTEM_STATUS.succeeded);

  if (analysesPath && !rootAnalysesPath) {
    return pathname;
  }

  if (pathwayId && analyses?.find(pathway => pathway.id === pathwayId)) {
    return `/projects/${id}/analysis/pathways/${pathwayId}`;
  } else if (systemId && succeededSystems?.find(system => system.id === systemId)) {
    return `/projects/${id}/analysis/systems/${systemId}`;
  } else if (analyses?.length) {
    return `/projects/${id}/analysis/pathways/${analyses[0].id}`;
  } else if (succeededSystems?.length) {
    return `/projects/${id}/analysis/systems/${succeededSystems[0].id}`;
  }

  return `/projects/${id}/analysis`;
};

const getCompareUrl = (data, location) => {
  const { pathname } = location;
  const {
    project: { id },
    comparisons: { results: comparisons },
  } = data;

  const rootComparisonsPath = pathname.match(/comparisons$/);
  const comparisonsPath = pathname.match(/comparisons/);

  if (comparisonsPath && !rootComparisonsPath) {
    return pathname;
  }

  if (comparisons?.length) {
    return `/projects/${id}/comparisons/${comparisons[0].id}`;
  }

  return `/projects/${id}/comparisons`;
};

export const getTabs = (location, data, params) => {
  const {
    project: { id },
    pathways,
    systems,
    comparisons,
  } = data;

  const pathwayUrl = getPathwayUrl(data, location, params);
  const analyzeUrl = getAnalyzeUrl(data, location, params);
  const compareUrl = getCompareUrl(data, location);

  const succeededPathways = getSucceededPathways(pathways?.results ?? []);

  return [
    {
      icon: <Squares2X2Icon className="h-5 w-5" />,
      url: `/projects/${id}/dashboard`,
      label: 'Dashboard',
      target: '_self',
      end: false,
    },
    {
      icon: <CubeIcon className="h-5 w-5" />,
      url: pathwayUrl,
      label: 'Build',
      target: '_self',
      end: false,
      dropdown: <BuildDropdownContent />,
    },
    {
      icon: <ChartBarIcon className="h-5 w-5" />,
      url: analyzeUrl,
      label: 'Analyze',
      target: '_self',
      end: false,
      dropdown: <AnalyzeDropdownContent pathways={succeededPathways} systems={systems?.results} />,
    },
    {
      icon: <ArrowsRightLeftIcon className="w-5 h-5" />,
      url: compareUrl,
      label: 'Compare',
      target: '_self',
      end: false,
      dropdown: <ComparisonDropdownContent comparisons={comparisons.results} />,
    },
  ];
};

export const getTopLinks = () => [{ url: '/', label: 'Home', icon: <HomeIcon className="w-5 h-5" /> }];

export const getBottomLinks = () => [
  {
    url: 'https://docs.sesame.software',
    label: 'Docs',
    target: '_blank',
    rel: 'noreferrer',
    icon: <DocumentIcon className="w-5 h-5" />,
  },
  ...(featureFlags.enableDatasource === 'true'
    ? [
        {
          icon: <DocumentChartBarIcon className="w-5 h-5" />,
          url: `/datasources/data_dashboard`,
          label: 'Data',
          target: '_blank',
          end: false,
        },
      ]
    : []),
];
