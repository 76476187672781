import { HOURS_PER_WEEK, startingHours } from '@/consts';
import { format, startOfMonth, endOfMonth, startOfYear, addDays, parseISO } from 'date-fns';

/**
 * Constructs an object where keys are extracted from elements of the given array,
 * and values are the elements themselves or their transformation based on a mapper function.
 * @template T
 * @param {T[]} array - The array of elements to be transformed into an object.
 * @param {string} keyName - The name of the property to use as the key for each element.
 * @param {(item: T) => any} [mapper=item => item] - Optional mapper function to transform each element before assigning it to the resulting object.
 * @returns {Object<string, T>} - The resulting object where keys are extracted from the elements of the array and values are the elements or their transformation based on the mapper function.
 */
export const keyBy = (array = [], keyName, mapper = item => item) => {
  return array.reduce((acc, current) => {
    const key = current[keyName];
    acc[key] = mapper(current);
    return acc;
  }, {});
};

/**
 * Creates an object by pairing elements of two arrays, where the elements of the first array become keys and the elements of the second array become values.
 *
 * @param {Array} [array1=[]] - The array containing keys to be used in the resulting object.
 * @param {Array} [array2=[]] - The array containing values to be used in the resulting object.
 * @returns {Object} An object created by pairing elements of the first array as keys and elements of the second array as values.
 * @example
 * const keys = ['a', 'b', 'c'];
 * const values = [1, 2, 3];
 * const obj = zipObject(keys, values);
 * // Output: {a: 1, b: 2, c: 3}
 */
export const zipObject = (array1 = [], array2 = []) => {
  return array1.reduce((obj, key, index) => ({ ...obj, [key]: array2[index] }), {});
};

export const removeEmpty = obj => Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

export const isDefined = value => typeof value !== 'undefined' && value !== null;

export const toArray = obj => Object.entries(obj).map(([name, value]) => ({ name, value }));

/**
 * Converts an array of objects into a single object, using specified key and value property names.
 * @param {Array.<Object>} array - The array of objects to convert into an object.
 * @param {string} [keyName='name'] - The name of the property to use as keys in the resulting object.
 * @param {string} [valueName='value'] - The name of the property to use as values in the resulting object.
 * @returns {Object} The resulting object where keys are taken from the specified property name and values are taken from another specified property name.
 */
export const arrayToObject = (array, keyName = 'name', valueName = 'value') => {
  return array?.reduce((acc, obj) => {
    const key = obj[keyName];
    const value = obj[valueName];
    acc[key] = value;
    return acc;
  }, {});
};

export const isEmpty = obj => !obj || Object.keys(obj).length === 0;

/**
 * Picks values from an array at specified indexes.
 *
 * @param {Array} array - The array from which values will be picked.
 * @param {Array<number>} indexes - An array of indexes specifying which values to pick from the original array.
 * @returns {Array} An array containing the values picked from the original array based on the given indexes.
 * @example
 * const array = ['a', 'b', 'c', 'd', 'e'];
 * const indexes = [1, 3, 4];
 * const selectedValues = pickAt(array, indexes);
 * // Output: ['b', 'd', 'e']
 */
export const pickAt = (array, indexes) => indexes.map(index => array[index]);

export const sort = array => {
  array.sort((a, b) => {
    if (a.name > b.name) return -1;
    if (a.name < b.name) return 1;
    return 0;
  });

  return array;
};

/**
 * Sorts an array of objects to prioritize a specific value in a specified field.
 *
 * @param {Array} array - The array of objects to sort.
 * @param {string} field - The name of the field to check for the specified value.
 * @param {any} value - The value to prioritize in the sorting.
 * @returns {Array} - The sorted array with the specified value at the front.
 */
export const sortByValue = (array, field, value) => {
  const arrayCopy = [...array];

  return arrayCopy.sort((a, b) => {
    const isAValue = a[field] === value;
    const isBValue = b[field] === value;

    if (isAValue && !isBValue) {
      return -1;
    }
    if (!isAValue && isBValue) {
      return 1;
    }

    return 0;
  });
};

export const runInterval = (execFn, delay1, delay2, changeDelayAfter, counter = 0) => {
  let timeoutId;

  const run = currentCounter => {
    const delay = currentCounter < changeDelayAfter ? delay1 : delay2;

    timeoutId = setTimeout(async () => {
      const result = await execFn();

      if (!result) {
        run(currentCounter + 1);
      }
    }, delay);
  };

  run(counter);

  return () => clearTimeout(timeoutId);
};

export const last = array => array?.[array?.length - 1];

export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

export const roundValue = (value, digits) => {
  if (isNaN(value)) {
    return value;
  }

  const rounded = value.toFixed(digits);

  return parseInt(rounded) === 0 ? 0 : rounded;
};

export const isObject = obj => obj === Object(obj);

export const isNumber = str => !isNaN(str) && !isNaN(parseFloat(str));

export const isInteger = str => {
  const num = Number(str);
  return Number.isInteger(num);
};

export const smartRound = (value, defaultPrecision = 0, minPrecision = 2, maxPrecision = 10) => {
  if (value >= 1) {
    return value.toFixed(defaultPrecision);
  }

  let precision = minPrecision;
  let roundedValue = value.toFixed(precision);

  while (parseFloat(roundedValue) === 0 && precision < maxPrecision) {
    precision++;
    roundedValue = value.toFixed(precision);
  }

  return roundedValue;
};

export const isBuildPath = url => {
  const { pathname } = new URL(url);

  return pathname.includes('/build/');
};

export const getMonthRanges = pages => {
  const monthRanges = [];

  for (let i = 0; i < pages.length; i++) {
    const firstDay = startOfMonth(new Date(2023, i, 1));
    const lastDay = endOfMonth(firstDay);
    const firstDayFormatted = format(firstDay, 'MMM') + ' ' + format(firstDay, 'd');
    const lastDayFormatted = format(lastDay, 'MMM') + ' ' + format(lastDay, 'd');
    const label = `${firstDayFormatted} - ${lastDayFormatted}`;

    monthRanges.push({ label, value: i });
  }

  return monthRanges;
};

export const getWeekRanges = pages => {
  return pages.map((page, index) => {
    return {
      label: `${getDateFromHours(index * HOURS_PER_WEEK)} - ${getDateFromHours((index + 1) * HOURS_PER_WEEK - 24)}`,
      value: index,
    };
  });
};

export const getDateFromHours = hours => {
  const days = Math.floor(hours / 24);
  const startDate = startOfYear(new Date(2023, 0, 1));
  const targetDate = addDays(startDate, days);
  const monthName = format(targetDate, 'MMM');
  const day = format(targetDate, 'd');

  return `${monthName} ${day}`;
};

export const convertToLocalDate = utcDateStr => {
  return parseISO(utcDateStr);
};

export const formatDate = (date, dateFormat = 'PPpp') => {
  return format(date, dateFormat);
};

export const getStartingHourForMonth = index => startingHours[index];

export const resolveAllArrays = async arrays => {
  const results = await Promise.all(arrays.map(innerArray => Promise.all(innerArray)));
  return results;
};

export const transpose = matrix => {
  return matrix.reduce((prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])), []);
};

export const flattenObject = (obj, parentKey = '') => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (isObject(value)) {
      Object.assign(acc, flattenObject(value, newKey));
    } else {
      acc[newKey] = value;
    }
    return acc;
  }, {});
};
