import axios from 'axios';
import config from '@/config';
import { toast } from 'react-toastify';
import authStore, { authActions } from '@/stores/authStore';
import { flattenObject, isObject } from '@/utils/miscUtils';

const { serverUrl } = config;
let errorId;

axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

const parseErrorMessage = error => {
  let message = 'Oops. Something went wrong - ';

  const contentType = error?.response?.headers?.['content-type'] ?? '';

  if (contentType.match('html') || !error?.response?.data) {
    message = error.message;
  } else {
    const { data } = error.response;

    if (data.detail) {
      message = data.detail;
    } else {
      const errors = data?.params ? data.params : data;
      message = '';

      Object.keys(errors).forEach(key => {
        if (key === 'non_field_errors') {
          // error does not pertain to a specific field
          message += data[key].join('\n');
        } else if (isObject(errors[key])) {
          const flattenedErrors = flattenObject(errors[key]);
          Object.entries(flattenedErrors).forEach(([flatKey, flatValue]) => {
            message += `${flatKey}: ${flatValue}\n`;
          });
        } else {
          // error pertains to a specific field
          message += `${key}: ${data[key]} \n`;
        }
      });
    }
  }

  return message;
};

axios.interceptors.request.use(reqConfig => {
  const { csrfToken } = authStore.getState();

  if (csrfToken) {
    if (['post', 'put', 'patch', 'delete'].includes(reqConfig.method.toLowerCase())) {
      reqConfig.headers['X-CSRFToken'] = csrfToken;
    }
  }

  if (!reqConfig.url.startsWith('http')) {
    reqConfig.url = `${serverUrl}${reqConfig.url}`;
  }

  return reqConfig;
});

axios.interceptors.response.use(
  response => response,
  async error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      authActions.logout();
    } else {
      // TODO: remove this after tea endpoint returns 200
      if (error?.request?.responseURL?.match(/tea|lca|coac|password|sweep/)) {
        return Promise.reject(error);
      }

      const message = parseErrorMessage(error);

      toast.clearWaitingQueue();

      if (!toast.isActive(errorId)) {
        errorId = toast.error(message);
      }
    }

    return Promise.reject(error);
  },
);
