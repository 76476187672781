import ComparisonCasesList from '@/components/comparisons/ComparisonCasesList';
import ComparisonsReport from '@/components/comparisons/ComparisonsReport';
import { Suspense, useEffect, useRef } from 'react';
import { Await, Outlet, useRouteLoaderData } from 'react-router-dom';
import WalkthroughButton from '@/components/core/WalkthroughButton';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { exportComparisonsDataUrl } from '@/api/comparisons';

const ComparisonRoute = () => {
  const data = useRouteLoaderData('comparison');

  const blockRef = useRef(null);

  useEffect(() => {
    blockRef.current.focus();
  }, []);

  return (
    <div className="bg-canvas focus:outline-none" ref={blockRef} tabIndex={-1}>
      <WalkthroughButton type="comparison" parentRef={blockRef} />
      <Suspense
        fallback={
          <div className="pl-8 pt-6">
            <span className="loading loading-dots loading-md"></span>
          </div>
        }
      >
        <Await resolve={data.promise} errorElement={<></>}>
          {comparison => (
            <>
              <div className="flex float-right mt-4">
                <a
                  href={exportComparisonsDataUrl(comparison.id)}
                  target="_blank"
                  rel="noreferrer"
                  className="right-5 top-5 btn btn-sm btn-secondary mr-4"
                >
                  <ArrowDownTrayIcon className="w-5 h-5" />
                  Export Data
                </a>
              </div>
              <div className="relative prose prose-sm px-6 py-5">
                <h3>{comparison?.name}</h3>
              </div>
              <ComparisonCasesList />
              <ComparisonsReport />

              <Outlet />
            </>
          )}
        </Await>
      </Suspense>
    </div>
  );
};

export default ComparisonRoute;
