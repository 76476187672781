export default {
  title: "What's new in Sesame 0.2.3 🎉",
  items: [
    {
      title: 'Systems in Pathways: Power System & AEM',
      description: 'Power systems and the asset electricity models can now be used in Pathways!',
    },
    {
      title: 'Electricity Demand Curve in Systems',
      description: 'Visualize the demand curve when setting up a system.',
    },
    {
      title: 'Added Electricity Profiles in the AEM',
      description: 'Warehouse and EV fleet were added to evaluate a broader range of scenarios.',
    },
    {
      title: 'Updated Documentation',
      description:
        'The techno-economic analysis methodology is now explained in extensive details, alongside all Modules documentation.',
    },
    {
      title: 'Improved Info Section',
      description: 'The Info sections of most Modules have been updated.',
    },
    {
      title: 'General Improvements and Fixes',
      description: 'New user warnings and minor bug fixes.',
    },
  ],
};
